<template>
  <div>
    <b-card
      class="text-primary"
      :title="$t('g.basicInformation')"
      v-if="userData"
    >
      <b-row>
        <b-col md="6">
          <h4>
            <feather-icon icon="MinusIcon" size="22"></feather-icon>
            {{ $t("g.name") }} : {{ userData.name }}
          </h4>
        </b-col>
        <b-col md="6">
          <h4>
            <feather-icon icon="MinusIcon" size="22"></feather-icon>
            {{ $t("g.Joining Date") }} : Joining Date
          </h4>
        </b-col>
        <b-col md="6">
          <h4>
            <feather-icon icon="MinusIcon" size="22"></feather-icon>
            {{ $t("g.phone_number") }} : {{ userData.phone_number }}
          </h4>
        </b-col>
        <b-col md="6">
          <h4>
            <feather-icon icon="MinusIcon" size="22"></feather-icon>
            {{ $t("g.educational_qualification") }} :
            {{ userData.educational_qualification }}
          </h4>
        </b-col>
        <b-col md="6">
          <h4>
            <feather-icon icon="MinusIcon" size="22"></feather-icon>
            {{ $t("g.evaluationDate") }} : {{ evaluationDate }}
          </h4>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="evaluationData" :title="$t('g.evaluation')">
      <b-row v-for="(item, index) in evaluationData" :key="index">
        <b-col class="mb-2 mt-2" cols="12">
          <h3 class="text-primary">
            <feather-icon icon="GitCommitIcon" size="25"> </feather-icon>
            {{ $t(`g.evaluations.${item.evaluation_title}`) }}
          </h3>
        </b-col>
        <b-col
          v-for="(question_item, index) in item.questions"
          :key="index"
          md="6"
          class="pl-1 pr-1 mb-1"
        >
          <b-row>
            <b-col md="8">
              <!-- <feather-icon icon="MinusIcon" size="15"></feather-icon> -->
              <span>
                {{ $t(`g.evaluations.${question_item.question}`) }}
              </span>
            </b-col>
            <b-col md="4">
              <b-form-checkbox
                v-model="question_item.status"
                value="yes"
                class="custom-control-primary d-inline mr-1 ml-1"
                >{{ $t("g.Yes") }}</b-form-checkbox
              >
              <b-form-checkbox
                v-model="question_item.status"
                value="no"
                class="custom-control-danger d-inline mr-1 ml-1"
                >{{ $t("g.No") }}</b-form-checkbox
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mt-2">
          <b-button @click="submitEvaluation" variant="primary">
            {{ $t("g.submit") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormCheckbox, BButton } from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import evaluationPagesData from "./evaluationPageData";
export default {
  components: { BCard, BRow, BCol, FeatherIcon, BFormCheckbox, BButton },

  created() {
    // check is param have an id === user id
    if (this.$route.params.id) {
      // Finding the right evaluation from the evaluation pages
      evaluationPagesData.forEach((page) => {
        switch (this.$route.name) {
          case page.pageName:
            this.getUserData(page.userDataUrl);
            this.evaluationData = page.data;
            break;
        }
      });
    } else {
      console.log("page not have id");
    }

    // reset revalustion status data
    // Check is data not empty
    if (this.evaluationData) {
      // loop in evaluation data
      this.evaluationData.forEach((item) => {
        // loop in questions array in evaluation data
        item.questions.forEach((el) => {
          // Check if status == true
          if (el.status) {
            // Reset the value to null
            el.status = null;
          }
        });
      });
    }
  },

  data() {
    return {
      currentId: null,
      userData: null,
      evaluationData: null,
      evaluationDate: new Date().toLocaleDateString("en-US"),
      selected: [],
    };
  },

  methods: {
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    // Get the user informations - call API
    getUserData(path) {
      this.currentId = this.$route.params.id;
      this.$http
        .get(path + this.currentId)
        .then((res) => {
          this.userData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Send data to the backend
    submitData(path, listPage) {
      this.$http
        .post(path, this.evaluationData)
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: listPage });
          }, 1000);
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },

    // Check func before submit data
    submitEvaluation() {
      // Find the current page and send its data through the information on the page evaluation pages
      evaluationPagesData.forEach((page) => {
        switch (this.$route.name) {
          case page.pageName:
            this.submitData(page.postDataUrl, page.listPage);
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
