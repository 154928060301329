export default [
  {
    evaluation_title:
      "Skill in performing business, developing and preparing reports",
    questions: [
      {
        question:
          "Has the ability to determine if the item to be tested is within the scopes of laboratories",
        status: null,
      },
      {
        question: "He has the Knowledge of acceptance and rejection criteria",
        status: null,
      },
      {
        question:
          "Has the ability to make an apology for an incompatible sample",
        status: null,
      },
      {
        question:
          "He has the ability to record incoming samples and develop the reception program to facilitate obtaining the required information",
        status: null,
      },
      {
        question:
          "Has the ability to scientifically verify cients or related authorities complaints and respond to them",
        status: null,
      },
      {
        question:
          "He has the ability to respond scientifically t to the inquiries of the relevant authorities regarding the samples received",
        status: null,
      },
      {
        question:
          "He has the Knowledge of how to preserve the samples and privacy",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Skill in the follow-up of work and guidance, coordination and distribution of work in order to achieve the SAITCO goal",
    questions: [
      {
        question:
          "Reviewing the received samples daily and makes sure of the capabilities to test them",
        status: null,
      },
      {
        question: "Ensure that samples are properly encoded in laboratories",
        status: null,
      },
      {
        question:
          "Has the ability to identify competent individuals to carry out sub/part tasks to complete the work system",
        status: null,
      },
      {
        question:
          "He has the Ability to operating the system and entering data of samples and results",
        status: null,
      },
      {
        question:
          "He has knowledge of the amount of time it takes for different tests and to review each laboratory if results are delayed",
        status: null,
      },
      {
        question: "Has the ability to create KPI for the   his subordinates",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to  follow up the  plan and objectives",
    questions: [
      {
        question: "Has the ability to follow up daily weekly monthly tasks",
        status: null,
      },
      {
        question:
          "He has the ability to follow up a training and promotion plan for his subordinates",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "The ability to analyze the data and decision-making and Provide ideas and proposals",
    questions: [
      {
        question:
          "Has knowledge of statistical calculations related to received samples (Document)",
        status: null,
      },
      {
        question:
          "Has the ability to provide ideas and suggestions for continual improvement",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Continuous Monitoring of the Effectiveness of the Quality System in  lab",
    questions: [
      {
        question:
          "Has an understanding of ISO 17025 and related standards regarding samples and how to handle them",
        status: null,
      },
      {
        question:
          "It has the ability to track samples and know the status of each sample in terms of the completion of the tests and the techtain etc",
        status: null,
      },
      {
        question:
          "He has the ability to do a daily review of the data and data of the issued reports and submit them on the relevant authorities website",
        status: null,
      },
      {
        question:
          "Has the ability to ensure that his subordinates have the ability and correct understanding of the implementation of the management system",
        status: null,
      },
      {
        question:
          "Has the ability to provide an improvement to the management system",
        status: null,
      },
      {
        question:
          "Maintains records and related documents in a safe and secure manner and is not liable to be lost",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The ability to Solve Technical and Managerial Problems",
    questions: [
      {
        question:
          "He has the ability to listen in a neutral manner to all his problems",
        status: null,
      },
      {
        question:
          "Has the ability to create a largely problem-free work environment",
        status: null,
      },
      {
        question:
          "Has the ability to discover the positive side in individuals",
        status: null,
      },
      {
        question:
          "He has the ability to evaluate who is in his of responsibility in an impartial manner",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Role models  of Punctuality of job",
    questions: [
      {
        question: "Commit to time (attendance - leave - discipline)",
        status: null,
      },
      {
        question: "Saves time while working",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Coordination and Communication with Technical and Administrative External Parties",
    questions: [
      {
        question:
          "He has the ability to communicate with the relevant authorities and establish coordination relationships in various fields",
        status: null,
      },
      {
        question:
          "He has the ability to communicate with clients to achieve their expectations and satisfaction",
        status: null,
      },
    ],
  },
];
