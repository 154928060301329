import technicalSupervisors from "./evaluationDataTechnicalSupervisors";
import generalManagerData from "./periodicEvaluationOfTheGeneralManagerOfQuality";
import financialManagerData from "./periodicEvaluationOfTheFinancialManager";
import samplesServicesData from "./periodicEvaluationOfTheSamplesServicesSupervisor";

export default [
  {
    data: technicalSupervisors,
    pageName: "PeriodicEvaluationOfTechnicalSupervisors",
    userDataUrl: "listOfCompanyDirectors/",
    postDataUrl: "https://api.npoint.io/bd1ce6326b271bece1de/",
    listPage: "ListOfTechnicalSupervisors",
  },
  {
    data: generalManagerData,
    pageName: "PeriodicEvaluationOfTheGeneralManagerOfQuality",
    userDataUrl: "listOfCompanyDirectors/",
    postDataUrl: "https://api.npoint.io/bd1ce6326b271bece1de/",
    listPage: "ListOfQualityGeneralManagers",
  },
  {
    data: financialManagerData,
    pageName: "PeriodicEvaluationOfTheFinancialManager",
    userDataUrl: "listOfCompanyDirectors/",
    postDataUrl: "https://api.npoint.io/bd1ce6326b271bece1de/",
    listPage: "ListOfFinancialManagers",
  },
  {
    data: financialManagerData,
    pageName: "PeriodicEvaluationOfTheTechnicalManager",
    userDataUrl: "listOfCompanyDirectors/",
    postDataUrl: "https://api.npoint.io/bd1ce6326b271bece1de/",
    listPage: "ListOfTechnicalManagers",
  },
  {
    data: samplesServicesData,
    pageName: "PeriodicEvaluationOfTheSamplesServicesSupervisor",
    userDataUrl: "listOfCompanyDirectors/",
    postDataUrl: "https://api.npoint.io/bd1ce6326b271bece1de/",
    listPage: "ListOfSampleServicesSupervisor",
  },
  ,
];
