export default [
  {
    evaluation_title:
      "Skill in performing business, developing and preparing reports",
    questions: [
      {
        question:
          "He has the ability to communicate with service providers to accurately determine what is required in coordination with the technical departments",
        status: null,
      },
      {
        question:
          "Has the ability to resolve the financial offers submitted in coordination with the technical departments",
        status: null,
      },
      {
        question:
          "He has the ability to get the best prices while achieving the required quality",
        status: null,
      },
      {
        question:
          "Has the ability to scientifically verify cients or related authorities complaints and respond to them",
        status: null,
      },
      {
        question:
          "Has the ability to respond to the inquiries of the relevant authorities accurately",
        status: null,
      },
      {
        question:
          "He is familiar with the prices of inspection evaluation and issuance of certificates according to the type of product and its requirements",
        status: null,
      },
      {
        question:
          "Has the ability to set renewal dates for the company s external documents such as Commercial Register Various work licenses Hiring decisions Insunce Various appropriations etc and inform the administration well in advance",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Skill in the follow up of work and guidance coordination and distribution of work in order to achieve the SAITCO goal",
    questions: [
      {
        question: "Follow up the renewal of various external documents",
        status: null,
      },
      {
        question:
          "Has the ability to identify competent individuals to carry out sub part tasks to complete the work system",
        status: null,
      },
      {
        question:
          "Has the ability to operate the system and enter various data",
        status: null,
      },
      {
        question: "Has the ability to create KPI for the   his subordinates",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to  follow up the  plan and objectives",
    questions: [
      {
        question: "Has the ability to follow up daily weekly monthly tasks",
        status: null,
      },
      {
        question:
          "He has the ability to follow up a training and promotion plan for his subordinates",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "The ability to analyze the data and decision-making and Provide ideas and proposals",
    questions: [
      {
        question:
          "Has knowledge of statistical calculations related to received samples (Document)",
        status: null,
      },
      {
        question:
          "Has the ability to provide ideas and suggestions for continual improvement",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Continuous Monitoring of the Effectiveness of the Quality System in  lab",
    questions: [
      {
        question: "Has an understanding of ISO 17025",
        status: null,
      },
      {
        question:
          "He has the ability to do a daily review of the data and data of the issued reports and submit them on the relevant authorities website",
        status: null,
      },
      {
        question:
          "Has the ability to ensure that his subordinates have the ability and correct understanding of the implementation of the management system",
        status: null,
      },
      {
        question:
          "Has the ability to provide an improvement to the management system",
        status: null,
      },
      {
        question:
          "Maintains records and related documents in a safe and secure manner and is not liable to be lost",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The ability to Solve Managerial Problems",
    questions: [
      {
        question:
          "He has the ability to listen in a neutral manner to all his problems",
        status: null,
      },
      {
        question:
          "Has the ability to create a largely problem-free work environment",
        status: null,
      },
      {
        question:
          "Has the ability to discover the positive side in individuals",
        status: null,
      },
      {
        question:
          "He has the ability to evaluate who is in his of responsibility in an impartial manner",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Role models  of Punctuality of job",
    questions: [
      {
        question: "Commit to time (attendance - leave - discipline)",
        status: null,
      },
      {
        question: "Saves time while working",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Coordination and Communication with Administrative External Parties",
    questions: [
      {
        question:
          "He has the ability to communicate with the relevant authorities and establish coordination relationships in various fields",
        status: null,
      },
      {
        question:
          "He has the ability to communicate with clients to achieve their expectations and satisfaction",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to dialogue and presentation of opinions",
    questions: [
      {
        question:
          "Take into account public morals in the dialogue with everyone",
        status: null,
      },
      {
        question:
          "Takes into account the differences in age, experiences and occupational status in the dialogue",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Assessing responsibility",
    questions: [
      {
        question: "Maintains impartiality and does not conflict of interest",
        status: null,
      },
      {
        question:
          "Maintains confidentiality and privacy of results and customer information",
        status: null,
      },
    ],
  },

  {
    evaluation_title: "Accept guidance and ready for implementation",
    questions: [
      {
        question: "Implement the directives and provide an opinion if require",
        status: null,
      },
      {
        question:
          "Carry out the instructions in a manner that does not conflict with impartiality and not conflict of interest",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The commitment to the wearing of safety",
    questions: [
      {
        question: "Take care of the general appearance",
        status: null,
      },
      {
        question: "He wears various safety devices when in the laboratory",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Relationships with Superiors",
    questions: [
      {
        question: "line manager respects and implements his directions",
        status: null,
      },
      {
        question:
          "Goes back to the direct boss and does not pass it except with his knowledge",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Relationships with Colleagues",
    questions: [
      {
        question:
          "Humble with his colleagues and those under his responsibility",
        status: null,
      },
      {
        question: "He is committed to public morals in dealing and dialogue",
        status: null,
      },
    ],
  },
];
