export default [
  {
    evaluation_title:
      "Skill in performing business, developing and preparing reports",
    questions: [
      {
        question:
          "Has the ability to define and apply the fundamentals of GLP for the staff",
        status: null,
      },
      {
        question:
          "Has the ability to evaluate the technatents  impartially and periodically",
        status: null,
      },
      {
        question: "Has the ability to make equipment performance report",
        status: null,
      },
      {
        question:
          "Has the ability to evaluate the providers impartially and periodically",
        status: null,
      },
      {
        question:
          "Has the ability to scientifically and technically verify cients or related authorities complaints and respond to them",
        status: null,
      },
      {
        question:
          "He has the ability to respond scientifically and technically to the inquiries of the relevant authorities",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Skill in the follow-up of work and guidance, coordination and distribution of work in order to achieve the SAITCO goal",
    questions: [
      {
        question:
          "Reviewing the received samples daily and makes sure of the capabilities to test them",
        status: null,
      },
      {
        question:
          "Has knowledge of the scope of laboratory work and its capabilities",
        status: null,
      },
      {
        question:
          "Has the ability to identify competent individuals in each test , and having a deputy for every vital job",
        status: null,
      },
      {
        question:
          "Has knowledge of the amount of time taken for various tests, and make request for extra time if required",
        status: null,
      },
      {
        question:
          "Has the ability to deal with samples received according to the available capabilities",
        status: null,
      },
      {
        question: "Has the ability to create KPI for the  Technicians",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to  follow up the  plan and objectives",
    questions: [
      {
        question: "Has the ability to follow up daily weekly monthly tasks",
        status: null,
      },
      {
        question:
          "He has the ability to  follow up  a technical training and promotion plan for his subordinates",
        status: null,
      },
      {
        question:
          "Has the ability to  implement of  a laboratory proficiency assurance plan ((ILC-PT)",
        status: null,
      },
      {
        question: "Has the ability to  implement maintenance  plan",
        status: null,
      },
      {
        question:
          "Has the ability to  implement  calibration and verification plan",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "The ability to analyze the results and decision-making and Provide ideas and proposals",
    questions: [
      {
        question:
          "Has knowledge of statistical calculations related to results (Document)",
        status: null,
      },
      {
        question:
          "Has knowledge of how to calculate the uncertainty of results (Question / Document)",
        status: null,
      },
      {
        question:
          "Has the ability to decide whether to accept or reject the test result while being aware of  risk of the test result",
        status: null,
      },
      {
        question: "Has the ability to provide opinions and interpretations",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to Make a Feasibility Study",
    questions: [
      {
        question:
          "Has the ability to exploit the available resources to expand the scope",
        status: null,
      },
      {
        question:
          "Has the ability to identify the elements of a feasibility study",
        status: null,
      },
      {
        question: "Has the power to decide whether or not to expand the  scope",
        status: null,
      },
      {
        question:
          "He has the ability to open new horizons for work under any  circumstances",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Continuous Monitoring of the Effectiveness of the Quality System in lab",
    questions: [
      {
        question: "Has the ability to do the constant review of specifications",
        status: null,
      },
      {
        question:
          "Has the ability to select service providers according to the specifications and requirements of each component",
        status: null,
      },
      {
        question: "Has the ability to do daily review of results and reports",
        status: null,
      },
      {
        question:
          "Has the ability to ensure that thetechnaaents have the ability and correct understanding of the implementation of the management system",
        status: null,
      },
      {
        question:
          "Has the ability to provide an improvement to the management system",
        status: null,
      },
      {
        question:
          "Maintains records and related documents in a safe and secure manner and is not liable to be lost",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The ability to Solve Technical and Managerial Problems",
    questions: [
      {
        question:
          "He has the ability to listen in a neutral manner to all his problems",
        status: null,
      },
      {
        question:
          "Has the ability to create a largely problem-free work environment",
        status: null,
      },
      {
        question:
          "Has the ability to discover the positive side in individuals",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Role models  of Punctuality of job",
    questions: [
      {
        question: "Commit to time (attendance - leave - discipline)",
        status: null,
      },
      {
        question: "Saves time while working",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Coordination and Communication with Technical and Administrative External Parties",
    questions: [
      {
        question:
          "He has the ability to communicate with the relevant authorities and establish coordination relationships in various technical fields",
        status: null,
      },
      {
        question:
          "He has the ability to communicate with clients to achieve their expectations and satisfaction",
        status: null,
      },
    ],
  },
];
