export default [
  {
    evaluation_title: "Skill in the development of plans and objectives",
    questions: [
      {
        question:
          "Has the ability to set the companys quality policy and objectives",
        status: null,
      },
      {
        question: "Has the ability to set strategic goals for the company",
        status: null,
      },
      {
        question:
          "Has the ability to determine the job description for all employees of the company",
        status: null,
      },
      {
        question: "Has the ability to identify training needs for All CABs",
        status: null,
      },
      {
        question:
          "Has the ability to set up a quality management system in accordance with ISO 9001-2015",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for CABS (laboratories) in accordance with ISO 17025: 2017",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for CABS (Inspections) in accordance with ISO 17020: 2012",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for the CABS (product certification body) in accordance with ISO 17065: 2012",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for CABS (a certification body for halal products) in accordance with the Gulf Specification GSO 2055-2:2010",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for the CABS (bodies providing audit and certification of management systems) in accordance with ISO 17021-2015",
        status: null,
      },
      {
        question:
          "Has the ability to set a quality system for the CABS (operating certification of persons) in accordance with ISO 17024: 2012",
        status: null,
      },
      {
        question:
          "Has the ability to develop a plan to identify, assess and address risks for all CABs",
        status: null,
      },
      {
        question:
          "Has the ability to develop internal audit plans in all CABs of organization",
        status: null,
      },
      {
        question:
          "Has the ability to develop a management review plan in all CABs of organization",
        status: null,
      },
      {
        question:
          "He has the ability to coordinate with the safeguard of impartiality",
        status: null,
      },
      {
        question: "Committee and determine the plan and agenda of meetings",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Follow up the plan and objectives Continuous Monitoring of the Effectiveness of the Quality System in lab",
    questions: [
      {
        question:
          "He has the ability to reach and communicate to top management",
        status: null,
      },
      {
        question: "Has the ability to implement quality policy and objectives",
        status: null,
      },
      {
        question:
          "Has the ability to implement the strategic goals of the company",
        status: null,
      },
      {
        question: "Has the ability to implement QMS procedures for all CABs",
        status: null,
      },
      {
        question:
          "Has the ability to ensure that all individuals have the ability and correct understanding of implementing QMS",
        status: null,
      },
      {
        question:
          "Has the ability to ensure that all staff have the ability and a correct understanding of the job description",
        status: null,
      },
      {
        question:
          "Has the ability to provide training needs externally or internally for staff in all CABs",
        status: null,
      },
      {
        question:
          "Has the ability to consolidate the principle of quality in all staff",
        status: null,
      },
      {
        question:
          "Has the ability to implement the effectiveness of the QMS and his recommendations for the necessary improvements and submit them to top management",
        status: null,
      },
      {
        question:
          "Has the ability to Organize and to carry out internal audit in all CABS",
        status: null,
      },
      {
        question:
          "Has ability to reviewing implementation document review plans",
        status: null,
      },
      {
        question:
          "Has the ability to follow up procedures of treatments of opportunities and risks",
        status: null,
      },
      {
        question:
          "Has the ability to manage management review meetings for all CABs",
        status: null,
      },
      {
        question:
          "Has the ability to follow up on management review recommendations",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "The ability to analyze the results and decision-making and Provide ideas and proposals",
    questions: [
      {
        question:
          "He has the ability to review top management regarding deviation from procedures and take the necessary actions",
        status: null,
      },
      {
        question:
          "He has the ability to process the outcomes of the meeting safeguard of impartiality Committee and take the necessary actions",
        status: null,
      },
      {
        question:
          "He has the ability to stop the work in cases of discovering any NCs and take necessary action",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The ability to Solve Managerial Problems",
    questions: [
      {
        question:
          "He has the ability to listen in a neutral manner to all his problems",
        status: null,
      },
      {
        question:
          "Has the ability to create a largely problem-free work environment",
        status: null,
      },
      {
        question:
          "Has the ability to discover the positive side in individuals",
        status: null,
      },
    ],
  },
  {
    evaluation_title:
      "Coordination and Communication with Administrative External Parties",
    questions: [
      {
        question:
          "Has the ability to investigate and/or select competent, impartial individuals to investigate",
        status: null,
      },
      {
        question:
          "He has the ability to ensure continued accreditation of CABs",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Role models  of Punctuality of job",
    questions: [
      {
        question: "Commit to time (attendance - leave - discipline",
        status: null,
      },
      {
        question: "Saves time while working",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Ability to dialogue and presentation of opinions",
    questions: [
      {
        question:
          "Take into account public morals in the dialogue with everyone",
        status: null,
      },
      {
        question:
          "Takes into account the differences in age, experiences and occupational status in the dialogue",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Assessing responsibility",
    questions: [
      {
        question: "Maintains impartiality and does not conflict of interest",
        status: null,
      },
      {
        question:
          "Maintains confidentiality and privacy of results and customer information",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Accept guidance and ready for implementation",
    questions: [
      {
        question: "Implement the directives and provide an opinion if require",
        status: null,
      },
      {
        question:
          "Carry out the instructions in a manner that does not conflict with  impartiality and  not conflict of interest",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "The commitment to the wearing of safety",
    questions: [
      {
        question:
          "He wears laboratory uniforms and is concerned with general appearance",
        status: null,
      },
      {
        question: "Wearing various safety devices- if applicable",
        status: null,
      },
    ],
  },
  {
    evaluation_title: "Relationships with Superiors ,Colleague",
    questions: [
      {
        question: "line manager respects and implements his directions",
        status: null,
      },
      {
        question:
          "Humble with his colleagues and those under his responsibility,  committed to public morals in dealing and dialogue",
        status: null,
      },
    ],
  },
];
